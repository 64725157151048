// Typography
// ============================================================================
// 69356 
// 63880
// 107530
$font-nav: "Muli-Bold";
$font-header: "Muli-SemiBold";

$footer-font: "Muli-Regular";

$primary-font: "Muli-Regular";
$primary-font-italic: "Muli-Italic";
$primary-font-bold: "Muli-Bold";
$primary-font-light: "Muli-Light";
$primary-font-semibold: "Muli-SemiBold";
$primary-font-extra-bold: "Muli-ExtraBold";
// 69358
$secondary-font-medium: "noto-serif";
$secondary-font-black: "NotoSerifSC-Black-Alphabetic";
// 58165
$secondary-font-Regular: "noto-serif";

$source-serif-bold: "Source Serif Pro Bold";

// Letter spacing to match comps
$letter-spacing: 0;

// Color Names
// ============================================================================
$primary-darkest: #003B4C;
$primary-dark: #00647C;
$primary: #00687F;
// 56062
$primary-lowlight:#CCE0E5;
// 56062
$secondary: #111718;
$secondary-light: #D9EAEF;
$secondary-lowlight:#F1FAFC;
$yellow: #FDB71A;
$red: #cc0000;
// 150789
$danger:#6a0000;
$gray:#5A5D66;
// 170054
$dark-charcoal: #333333;
$midgray: #DBDEE0;
$light-gray: #F2F2F2;
$white: #ffffff;
$black: #000000;
$secondary-black:#012934;
$black-knight: #01171D;
$eastern-blue: #00819f;
$bon-blue: #028FAA;
$jagged-ice: #C7E8EF;
// 61642
$strom-grey: #747579;
// 56062
$shadow-blue: rgba(0, 81, 174, 0.07);
// 56062

$night-rider: #323232;
/* 38892 */
$secondary-eastern-blue: #027A92;
/* 38892 */
/* 37091 start */
$pattens-blue: #D9EAEF;
/* 37091 end */
$bright-gray: #5a5d66;
/*121291*/
$metallic-yellow: #ffc90e;
$pastel-orange: #fbb84b !default;
$mango-loco: #fdb71b;
$honeydew-sand: #EDCF8D;
$nobel: #979797 !default;
$bright-orange: #fab84b !default;
$bittersweet: #0087a4 !default;
$tan: #4b200b !default;
$cod: #d7cec9 !default;
$delrio: #ab9a91 !default;
$linkwater: #bdbec1 !default;
$lagoon: #006479 !default;
$spindle: #bec0c3 !default;
$suva: #939393 !default;
$lgrey: #c9c9c9 !default;
$lagoon: #00657c !default;
$white-smoke: #f5f5f5 !default;
$dark-smoke: #dcdcdc !default;
$gainsboro: #dcdcdc !default;
$light-gray2: #edebeb !default;
/*39943*/
$bright-green:  #008000 !default;
// 55926,78210
$ocean-green-pearl: #00A887 !default;
$azure-lowlight: #F0F9FB;
$zircon:#E7E8E8;
// Container widths
// ============================================================================
$container-max-widths: (
    md: 720px,
    lg: 940px,
    xl: 1200px
);
