// 69359
// @import 'base/homePage';
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "variables";
@import 'components/buttons';
// 168198

h1.page-title {
    background-color: transparent;
    color: white;

    &::before {
        background-color: transparent;
    }
}

.request-info {
    .invalid-feedback {
        color: $red;
    }
}

.homepage-footnotes {
    margin: 10px 10px;
}

.footnote {
    &__detail {
        font-size: 12px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }
}
// 68510
.hero-banner {
    width: 100vw;
    max-width: calc(100vw);
    margin-left: calc((100vw - 100%) / -2);
    padding: 0;
    position: relative;

    h1 {
	   &:not(.featured_banner_heading) {
	        font-size: 24px;
	        font-weight: bold;
	        line-height: 1.4;
	        font-family: $secondary-font-black;
	        margin-bottom: 10px;
	        letter-spacing: normal;
	        text-align: left;
	
	        @include media-breakpoint-up(md) {
	            font-size: 45px;
	            line-height: 72px;
	        }
		}
    }

    img {
        height: 100%;
        width: 100%;
        display: block;
    }

    p {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.36;
        font-family: $primary-font-semibold;
        letter-spacing: normal;
        text-align: left;

        @include media-breakpoint-up(md) {
            font-size: 20px; 
            line-height: 34px;
            margin-bottom: 24px;
        }

        @media (max-width: 1200px){
            max-width: inherit;
            text-align: center;
            font-size: 20px;
            line-height: 30px;
        }
        @media (max-width: 1100px){                
            font-size: 15px;
            line-height: 24px;
        }
        @media (max-width: 767px){
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 10px;
        }
    }

    .hero-link {
        display: contents;
        text-decoration: none;
    }

    .hero-bg-image {
        height: 100%;
        width: 100%;
    }

    .banner-content {
        transform: translate(0, 0);
        padding-right: 0;
        max-width: 60%;
        box-sizing: border-box;
        @media (max-width: 1200px){            
            padding: 0 15px;
            max-width: inherit;
        }
    }
    
    .hero-carousel-item {
        position: relative;
        height: 100%;

        .c-module {
            &__featured_banner__cta {
                max-width: inherit;
                
                .btn{
                    padding: 13px 32px;

                    @media (max-width: 767px){
                        font-size: 14px;
                        padding: 9px 32px;
                    }
                }                
            }

            &__featured_banner__text {
                transform: translate(0%, 0%);
                width: 100%;
                left: 0;
                max-width: inherit;
                padding: 7% 10%;
                top: 0;
                bottom: 0;
                margin: auto;
                background: rgba(0,0,0,0.36);

                @media (max-width: 1200px){
                    max-width: inherit;
                    text-align: center;
                }
            }

            &__featured_banner__img {
                height: 100%;

                img {
                    object-fit: cover;
                }
            }
        }

        &.linear-gradient, &.solid-slant, &.solid-square {
            @include media-breakpoint-up(md) {
                display: flex !important;
                flex-direction: row-reverse !important;
                align-items: stretch;
            }

            .c-module {
                &__featured_banner__text {
                    flex: 1;
                    transform: translate(0, 0);
                    position: static;
                    height: 100%;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    box-sizing: border-box;

                    @include media-breakpoint-up(lg) {
                        align-items: flex-end;
                        padding-right: 0;
                    }

                    @include media-breakpoint-up(xl) {
                        position: absolute;
                        left: 0;
                        top: 0;
                        max-width: 65%;
                        padding-right: 7%;
                    }
                    @media (max-width: 1200px){
                        padding-right: 7%;
                        max-width: inherit;
                        width: 100%;
                        position: absolute;
                    }
                }

                &__featured_banner__img {
                    display: flex;
                    justify-content: flex-end;
                    flex: 1;
                    height: auto;
                    
                    .hero-bg-image {
                        position: absolute;

                        @include media-breakpoint-up(xl) {
                            position: relative;
                        }

                        img {
                            object-position: 60% 25%;

                            @include media-breakpoint-up(xl) {
                                object-position: 100%;
                            }

                            
                        }
                    }
                }
            }
        }

        &.linear-gradient {
            .c-module { 
                &__featured_banner__text {
                    background: linear-gradient(91deg, $primary 1%, $primary 67%, rgba(0, 101, 124, 0) 99%);

                    @include media-breakpoint-up(md) {
                        background: linear-gradient(91deg, $primary 0%, $primary 67%, rgba(0, 101, 124, 0) 100%);
                    }

                    @include media-breakpoint-up(lg) {
                        background: linear-gradient(91deg, $primary 0%, $primary 77%, rgba(0, 101, 124, 0) 100%);
                    }
                    @media (max-width: 1200px){
                        background: rgba(37, 47, 49, 0.54);
                    }
                }

                &__featured_banner__img {
                    @include media-breakpoint-up(lg) {
                        max-width: 45%;
                    }

                    @include media-breakpoint-up(xl) {
                        max-width: 50%;
                    }
                }
            }
        }

        &.solid-slant {
            h2, p {
                color: $primary;
            }
            
            .c-module { 
                &__featured_banner__text {
                    align-items: baseline;
                    max-width: 60%;
                    background: linear-gradient(94deg, $white, $white 85%, gray 85.3%, transparent 0);
                    padding-right: 7%;

                    @include media-breakpoint-up(md) {
                        padding-right: 10%;
                        background: linear-gradient(100deg, $white, $white 82%, gray 82.3%, transparent 0);
                    }

                    @include media-breakpoint-up(xl) {
                        align-items: flex-end;
                        max-width: 45%;
                    }
                    @media (max-width: 1200px){
                        background: rgba(37, 47, 49, 0.54);
                        max-width: inherit;
                        padding-right: 7%;
                    }
                }

                &__featured_banner__img {
                    @include media-breakpoint-up(lg) {
                        max-width: 40%;
                    }

                    @include media-breakpoint-up(xl) {
                        max-width: 65%;
                    }

                    .hero-bg-image {
                        img {
                            object-position: 70% 100%;
                            object-fit: cover;
                            transform: translate(30%, 0);

                            @include media-breakpoint-up(md) {
                                transform: translate(25%, 0);
                                object-fit: cover;
                                object-position: 0 50%;
                            }

                            @include media-breakpoint-up(xl) {
                                transform: translate(0, 0);
                            }
                            @media (max-width: 1200px){
                                transform: translate(0, 0);
                            }
                            @media  (min-width: 768px) and (max-width: 768px) {
                                object-position: 70% 55%;      
                            }
                        }
                    }
                }
            }
        }

        &.solid-square {
            .c-module { 
                &__featured_banner__text {
                    background: linear-gradient(91deg, $white 1%, $white 67%, rgba(0, 101, 124, 0) 100%);

                    @include media-breakpoint-down(sm) {
                        max-width: 70%;
                    }

                    @include media-breakpoint-up(md) {
                        max-width: 50%;
                    }

                    @include media-breakpoint-up(xl) {
                        max-width: 60%;
                        background: linear-gradient(91deg, $white 1%, $white 90%, rgba(0, 101, 124, 0) 100%);
                    }

                    @media (max-width: 1200px){
                        background: rgba(37, 47, 49, 0.54);
                        max-width: inherit;
                    }

                    .banner-content {
                        padding: 15px;
                        box-sizing: border-box;
                        background-color: $primary;

                        @include media-breakpoint-up(md) {
                            padding: 30px;
                        }

                        @include media-breakpoint-up(xl) {
                            padding: 50px;
                        }

                        @media (max-width: 1200px){
                            background: transparent;
                        }
                        @media (min-width: 1201px){
                            .c-module__featured_banner__heading, .c-module__featured_banner__body p{
                                color: $white !important;
                            }
                        }
                        
                    }
                }

                &__featured_banner__img {
                    .hero-bg-image {
                        img {
                            @include media-breakpoint-up(xs) {
                                object-position: 35% 100%;
                                transform: translate(20%, 0);
                            }

                            @include media-breakpoint-up(md) {
                                transform: translate(25%, 0);
                                object-fit: cover;
                                object-position: 0 0;
                            }

                            @include media-breakpoint-up(xl) {
                                object-position: 100% 20%;
                                transform: translate(25%, 0);
                            }
                            @media (max-width: 1200px){
                                background: rgba(37, 47, 49, 0.54);
                                transform: translate(0, 0);
                            }
                            @media  (min-width: 768px) and (max-width: 768px) {
                                object-position: 35% 40%;      
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-track {
        display: flex !important;
        align-items: stretch;
    }
    
    .slick-slide {
        height: inherit !important;
        flex: 1;
    }

    .slick-slide > div{
        height: 100% !important;
        max-height: 500px;
        @media (max-width: 767px){
            max-height: 320px;
        }
        @media (max-width: 600px){
            max-height: 200px;
        }
    }

    .pause-resume-slider {
        position: absolute;
        z-index: 1;
        right: 35px;
        bottom: 0;
        background: transparent;
        border: 0;
        box-sizing: border-box;
        opacity: .50;

        &:hover {
            color: $pastel-orange;
            opacity: 1;
        }

        &.pause {
            @include sliderBannerBtns(pause, '', 45px);
        }

        &.play {
            @include sliderBannerBtns(play, '', 45px);
        }
    }
}
// 47551
%linebar-heading {
text-align: center;
width: auto;
margin: 0;
font-family: $secondary-font-medium;
font-size: 18px;
line-height: 19px;
color: $primary-darkest;
font-weight: normal;
letter-spacing: 0;
@include media-breakpoint-up(md) {
    text-align: center;
    font-size: 36px;
    line-height: 46px;
}

&:after{
    content:'';
    height: 2px;
    width:64px;
    background: $yellow;
    margin-top:10px;
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;
    display: block;
    @include media-breakpoint-up(md) {
        margin-top:16px;
        margin-bottom: 43px;
    }
    @include media-breakpoint-down(sm){
        margin-bottom: 16px;
    }
}
}
// 47551
// 49178
.caq{
    .linebar-heading{
        @extend %linebar-heading;
    }
    // 47551
	h1.primary-heading, h2, h3{
	  font-family: $primary-font-extra-bold;
      font-size: 18px;
      line-height: normal;
      font-weight: normal;
      color: $secondary-black;
      margin-bottom: 24px;
      @media (max-width: 768px){
                font-size: 14px;
      }
	}
    // 75564
    .accordion {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

	    .card{
		  background:#f1fafc;
            @media(max-width:768px){
                margin-left:-15px;
                margin-right:-15px;
            }
		    &__card{
                &-header{
                    &__span{
                        color: #012934;
                        font-weight:600;
                        padding:14px;
                        font-size:16px;
                        line-height:26px;
                        text-transform: capitalize;
                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                            line-height: 21px;
                        }
                        &:focus {
                            outline:0px !important;
                            .btn-icon-toggle{
                                outline: 2px solid #434343;
                            }
                        }

                    }
                }
                &-body__answer{
                    font-family: $primary-font;
                    color: #003B4C;
                    font-size:16px;
                    line-height:26px;
                    @include media-breakpoint-down(sm) {
                        font-size: 12px;
                        line-height: 21px;
                    }
                    &__padding {
                        padding:0px 34px 20px 16px;
                        }
                    }

			    }

                &__border{
                    border: 1px solid #D9EAEF;
                    margin-bottom: 20px!important;
                    &.shadow{
                        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15)!important;
                    }
                }

		    }
            .card-collapse{
                .icon-minus{
                    display: inline-block;
                    background: url(../../images/icon-plus.svg) no-repeat;
                    background-size:100%;
                    width:17px;
                    height:17px;
                }
                .icon-plus{
                    display: none;
                }
                &.active{
                    .icon-minus{
                        display: none;
                    }
                    .icon-plus{
                        display: inline-block;
                        background: url(../../images/icon-minus.svg) no-repeat;
                        background-size: 100%;
                        width:17px;
                        height:17px;
                    }
                }
            }
            .hide {
                display: none;
            }
            .question-container{
                &__margin-top{
                    margin-top:15px;
                }
                &__padding{
                    padding-left:0;
                    padding-right:0;
                }
                &__header{
                    &__padding{
                        padding-left:32px;
                    &__margin{
                        margin-top:0px;
                        margin-bottom:25px;
                        }
                    }
                }
            }
		.learn-more-container{
			padding: 8px 16px 20px 0px;
			.btn-link{
				font-family: $primary-font-semibold;
	            font-size: 16px;
	            line-height: 26px;
	            color: $primary !important;
	            text-decoration: underline;
	            @media (max-width:768px){
	                 font-size: 12px;
	                 line-height: 18px;
	           	}
			}
		}
    }
}

// 47268
%sublinebar-heading {
    text-align: center;
    width: auto;
    margin: 0;
    font-family: $secondary-font-medium;
    font-size: 24px;
    line-height: 36px;
    color: $primary-darkest;
    font-weight: normal;
    letter-spacing: 0;
    @include media-breakpoint-down(sm) {
        font-size: 18px;
        line-height: 26px;
    }

    &:after {
        content: "";
        height: 2px;
        width: 64px;
        background: $yellow;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-right: auto;
        margin-left: auto;
        display: block;
    }
}

// 47268
// 47551
// 105364
.storepage-tilescard {
    .linebar-heading {
        @extend %linebar-heading;
        @include media-breakpoint-down(sm) {
            &:after {
                margin-bottom: 36px;
            }
        }
    }
    .card {
        @include media-breakpoint-down(sm) {
            &:nth-last-of-type(odd) {
                .card-body {
                    padding: 0px 15px 32px 0px;
                }
            }
            &:nth-last-of-type(even) {
                .card-body {
                    padding: 0px 0px 32px 15px;
                }
            }
        }
        .card-body {
            padding: 0px 22px 44px;
            .photo-tile-figure {
                margin-bottom: 30px;
                height: 50px;
                .photo-tile-image  {
                    max-width: 50px;
                }
            }
            // 69356
            .card-title {
                font-family: $primary-font;
                font-size: 18px;
                line-height: 26px;
                color: $night-rider;
                margin-bottom: 20px;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 16px;
                    font-family: $primary-font-bold;
                    font-weight: bold;
                }
            }
            .card-text {
                font-family: $primary-font;
                font-size: 16px;
                line-height: 26px;
                color: $bright-gray;
                @include media-breakpoint-down(sm) {
                    font-size: 12px;
                    line-height: 20px
                }
            }

        }
    }
}
// 47551
// 58171
// 99990
// 100031
.home-page {
    > .experience-component {
        &:first-of-type{
            padding-bottom: 30px;
            @include media-breakpoint-down(sm) {
                padding-bottom:20px;
            }

        }
    }
}

.latestatsll {
    margin-right: -15px!important;
    margin-left: -15px!important;
    @include media-breakpoint-down(lg) {
        margin-right: -23px!important;
        margin-left: -23px!important;      
    }
    .experience-component {
        position: relative;
        margin-bottom: 1rem;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 7px;
        padding-left: 7px;

        @include media-breakpoint-up(lg) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            padding-right: 15px;
            padding-left: 15px;
        }
        // 125893
        @include media-breakpoint-up(md) { 
            margin-top: 24px;
            margin-bottom: 12px;
        }
        &.experience-homepage_assets-blogFeedColumn {
            @include media-breakpoint-down(xs) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}