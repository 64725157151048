@mixin button-hover($bg-color, $txt-color, $bd-color){
    background-color: $bg-color;
    border: 2px solid $bd-color;
    color: $txt-color !important;
    cursor: pointer;
}

// Button (mixin)
@mixin button($bg-color: $primary, $txt-color: $white, $bd-color: $primary, $bgh-color: transparent, $txth-color: $primary, $bdh-color: $primary) {
    background-color: $bg-color;
    border: 2px solid $bd-color;
    color: $txt-color !important;
    text-transform: uppercase;
    &:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
        @include button-hover($bgh-color, $txth-color, $bdh-color);
    }
}

// Transparent Button (with primary blue outline, hover reverse)
.button-outline,
.btn-outline-primary {
    @include button(transparent, $primary, $primary, $primary, $white, $primary);
}

.btn-primary {
    @include button;
    font-family: $primary-font-bold;
    font-weight: 600;
}
// 69356
.btn-secondary {
    @include button($yellow, $black, $yellow, $bright-orange, $white, $bright-orange);
    font-family: $primary-font-bold;
    font-weight: bold;
}

//slider hover mixin
@mixin sliderHover($font-size: 45px, $opacity: 1){
    font-size: $font-size;
    color: $pastel-orange;
    opacity: $opacity;
}

// slider button mixin
@mixin sliderBtns($direction: '', $color: $black, $font-size: 45px) {
    &:before {
        font-family: "FontAwesome";
        font-size: $font-size;
        color: $color;
        @if $direction == left {
            content: '\f053';
            opacity: .25;
        } @else if $direction == right {
            content: '\f054';
            opacity: .25;
        } @else {
            content: '\f111';
        }
    }

    &:hover:before,focus:before {
        @if $direction == '' {
            @include sliderHover(25px);
        } @else {
            @include sliderHover();
        }
    }
}

// home banner pause/play button
@mixin sliderBannerBtns($actions: '', $color: $black, $font-size: 45px) {
    &:before {
        font-family: "FontAwesome";
        font-size: $font-size;
        color: $color;
        @if $actions == pause {
            content: '\f28b';
        } @else {
            content: '\f144';
        }
    }
}
